import "core-js/modules/es.array.push.js";
import PublicHeader from "./Header";
import PublicFooter from "./Footer";
import BillingPaymentForm from "@/components/Billing/PaymentForm";
export default {
  meta: {
    title: "Успешная регистрация"
  },
  components: {
    PublicHeader,
    BillingPaymentForm,
    PublicFooter
  },
  data() {
    return {};
  },
  mounted() {
    // this.$telemetry.reachGoal("signup");
  },
  methods: {
    onComplete() {
      this.$router.push(this.$route.params.backRoute || "/");
    },
    onSkip() {
      this.$router.push(this.$route.params.backRoute || "/");
    }
  }
};